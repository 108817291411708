@font-face {
  font-family: 'playfair Display';
  src: url('../src/assets/fonts/playfairDisplay/PlayfairDisplay-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'roboto slab';
  src: url('../src/assets/fonts/robotoSlab/RobotoSlab-Regular.ttf');
}
:root {
    --lightgray: #D9D9D9;
    --lightWhite: #fff;
    --gray: #9c9c9c;
    --lightBlue: #00ccff;
    --orange: #f48915;
    --darkGrey: #464D53;
    --caloryCard: #656565;
    --planCard: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
    --appColor:  #3c3f45;
    --textPrimary: #222222;
    --textSecondary: #777777;
    --textMain: #00224f;
    --btnColorPrimary:  #007bff;
    --btnColorSecondary:  #1560BD;
    --btnColorHover:  #cda972;
    --fontPrimary: 'playfair display', 'roboto slab', serif;
  }
/* body{
  background-image: url('assets/hero-bg.png');
  background-position: center center;
  padding: 0%;
  margin: 0%;
} */

*, ::before, ::after {
  box-sizing: inherit;
}

html {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  text-size-adjust: 100%;
}

.main-font{
  font-family: 'playfair display', 'roboto slab', serif !important;
  color: #fff;
}
h3{
  font-weight: 400;
  line-height: 1.167;
  letter-spacing: 0em;
  color: var(--textMain);
  font-size: 2.2rem;
}
@media (max-width: 900px) {
  h3{
   font-size: 2.0em !important;
  }
 }
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", serif !important;
  color: var(--fontPrimary) ;
  font-weight: 700;
  }
h1,
.h1 {
  font-size: 70px;
  font-weight: 700; }
h2,
.h2 {
  font-size: 3.55rem !important;
  font-weight: 500 !important;
  }
  @media (max-width: 768px) {
    h2,
    .h2 {
      font-size: 2.25rem !important;
    }
     }
     @media (min-width: 769px) and (max-width: 1024px) {
      h2,
      .h2 {
        font-size: 2.65rem;
      }
    }
    h2 {
      font-weight: 700;
      position: relative;
      }
.d-block::before {
  content: "";
  width: 80px;
  height: 3px;
  position: absolute;
  bottom: -2px;
  background-color: var(--textMain);
  }
  .header-text{
      color: var(--textMain) !important; 
      font-weight: 700 !important;
  }
.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
.scroll-to-top-button.visible {
  opacity: 1;
  visibility: visible;
}